import PropTypes from 'prop-types';
import {
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import { SvgIcon, Typography } from '@mui/material';
import I18n from '@app/i18n';
import { SEARCH_TYPES } from '@app/constants/findMedicalCare';

import SavedItem from './SavedItem';
import SavedItems from './SavedItems';

export const SEARCH_SUGGESTIONS = [
  {
    displayNameKey: 'primaryCare',
    type: SEARCH_TYPES.SPECIALTY,
    id: '0c949aa8-6729-41c2-9d3a-5bd4a6966363',
    value: 'Primary Care Provider',
  },
  {
    displayNameKey: 'urgentCare',
    type: SEARCH_TYPES.LOCATION_TYPE,
    id: '70088859-48e9-4bc1-bc46-df3079b1a0ed',
    value: 'Urgent Care',
  },
  {
    displayNameKey: 'hospital',
    type: SEARCH_TYPES.LOCATION_TYPE,
    id: 'hospital',
    value: 'Hospital',
  },
  {
    displayNameKey: 'emergencyRoom',
    type: SEARCH_TYPES.LOCATION_TYPE,
    id: 'emergencyRoom',
    value: 'Emergency Room',
  },
  {
    displayNameKey: 'obgyn',
    type: SEARCH_TYPES.SPECIALTY,
    id: '9c470211-e3ea-4963-9568-8d8e9b8f5c44',
    value: 'Obstetrics and Gynecology',
  },
  {
    displayNameKey: 'dermatology',
    type: SEARCH_TYPES.SPECIALTY,
    id: 'b9dc44e1-6add-41f8-8df4-a8ef9cea706a',
    value: 'Dermatologist',
  },
  {
    displayNameKey: 'gastroenterology',
    type: SEARCH_TYPES.SPECIALTY,
    id: 'ca571ba5-da97-4c9a-8289-2aaac198d4b2',
    value: 'Gastroenterologist',
  },
  {
    displayNameKey: 'chiropractor',
    type: SEARCH_TYPES.SPECIALTY,
    id: '167797fb-c9e0-4222-a287-fe33e8dd4ed6',
    value: 'Chiropractor',
  },
];

const SearchSuggestions = ({
  onSuggestionClick,
  specialtiesList = SEARCH_SUGGESTIONS,
}) => {
  const { t } = I18n.useTranslation();
  return (
    <SavedItems
      collapsible={false}
      title={t('findMedicalCare.searchSuggestions.title')}
      titleIcon={
        <SvgIcon
          component={MagnifyingGlassCircleIcon}
          color="secondary"
          sx={{ fontSize: '24px' }}
          viewBox="0 -2 24 24"
        />
      }
    >
      {specialtiesList.map(item => (
        <SavedItem
          onClick={() => onSuggestionClick(item)}
          key={item.displayNameKey}
        >
          <SvgIcon component={MagnifyingGlassIcon} viewBox="0 -2 26 26" />
          <Typography>
            {t(`findMedicalCare.searchSuggestions.${item.displayNameKey}`)}
          </Typography>
        </SavedItem>
      ))}
    </SavedItems>
  );
};

SearchSuggestions.propTypes = {
  onSuggestionClick: PropTypes.func,
  specialtiesList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

export default SearchSuggestions;
