import { useState } from 'react';
import PropTypes from 'prop-types';
import { analytics } from '@benniehealth/core-js';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Collapse, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import I18n from '@app/i18n';
import { track } from '@app/helpers/analytics';

const Root = styled('div')(({ theme }) => ({
  '& .title': {
    display: 'inline-flex',
    marginLeft: theme.spacing(1),
  },
  '& .title-wrapper': {
    borderBottom: '1px solid rgb(220, 224, 231)',
  },
  '& .button': {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .try-again-text': {
    display: 'inline-flex',
  },
}));

const SavedItems = ({
  children,
  collapsible = true,
  initialShownItemCount = 3,
  empty,
  error,
  isFetching,
  onTryAgainClick,
  title,
  titleIcon,
}) => {
  const { t } = I18n.useTranslation();
  const [showAll, setShowAll] = useState(false);

  // TODO Generalize the button text constants
  const getShowAllBtnText = showAllProvidersCurrentState =>
    showAllProvidersCurrentState === true
      ? `${t('findMedicalCare.savedProviders.showLess')}`
      : `${t('findMedicalCare.savedProviders.showAll')}`;

  const toggleShowAll = () => {
    setShowAll(isShowingAll => {
      track(
        analytics.common.getEventType(
          analytics.findADoctor.FIND_A_DOCTOR,
          // TODO Update this analytics constant to SHOW_ALL
          isShowingAll
            ? analytics.findADoctor.SHOW_LESS
            : analytics.findADoctor.SHOW_MORE,
        ),
      );
      return !isShowingAll;
    });
  };

  const content = () => {
    if (isFetching) {
      return (
        <Box mt={2}>
          <CircularProgress color="primary" size={20} />
        </Box>
      );
    }

    if (error) {
      return (
        <Box mt={2} display="flex" alignItems="center">
          <Box>
            <Typography className="try-again-text">{error}</Typography>
          </Box>

          <Box ml={1}>
            <Button className="button" onClick={onTryAgainClick}>
              <Typography>{I18n.t(`common.pleaseTryAgain`)}</Typography>
            </Button>
          </Box>
        </Box>
      );
    }

    if (!children || children.length === 0) {
      return (
        <Box mt={2}>
          <Typography>{empty}</Typography>
        </Box>
      );
    }

    return children.map((child, index) => {
      if (!collapsible || (!showAll && index < initialShownItemCount)) {
        return child;
      }
      return (
        <Collapse
          key={`collapse-${child.key}`}
          orientation="vertical"
          in={showAll}
        >
          {child}
        </Collapse>
      );
    });
  };

  return (
    <Root>
      <Box
        className="title-wrapper"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={6}
        pb={2}
      >
        <Box display="flex" alignItems="center">
          <Box className="title-icon">{titleIcon}</Box>
          <Typography variant="h4" className="title">
            {title}
          </Typography>
        </Box>

        {collapsible && children.length > initialShownItemCount && (
          <Button
            className="button"
            color="secondary"
            onClick={toggleShowAll}
            disableRipple
          >
            {getShowAllBtnText(showAll)}
          </Button>
        )}
      </Box>
      {content()}
    </Root>
  );
};

SavedItems.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  collapsible: PropTypes.bool,
  empty: PropTypes.node,
  error: PropTypes.node,
  initialShownItemCount: PropTypes.number,
  isFetching: PropTypes.bool,
  onTryAgainClick: PropTypes.func,
  title: PropTypes.string,
  titleIcon: PropTypes.node,
};

export default SavedItems;
