/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import { setState } from '@app/helpers/store';
import { SEARCH_TYPES } from '@app/constants/findMedicalCare';

const initialState = {
  currentSearchParams: {},
  errorDeletingSavedProvider: null,
  errorFetchingSavedProviders: null,
  errorFetchingSearchResults: null,
  errorFetchingItem: null,
  errorSavingProvider: null,
  hasFetchedSavedProviders: false,
  hasFetchedSearchResults: false,
  isFetchingSearchResults: false,
  isFetchingItem: false,
  isFetchingSavedProviders: false,
  isSettingSavedProvider: false,
  shouldDeleteItem: null,
  savedFacilities: [],
  savedProviders: [],
  searchResults: {},
  searchResultsOrder: [],
  searchPagination: {},
};

const findMedicalCareSlice = createSlice({
  name: 'findMedicalCare',
  initialState,
  reducers: {
    searchRequest: {
      prepare: payload => ({
        payload: {
          ...payload,
          type: payload?.type || SEARCH_TYPES.PROVIDER,
          page: payload?.page || 1,
          insuranceIds: Array.isArray(payload?.insuranceIds)
            ? payload.insuranceIds.join(',')
            : payload.insuranceIds,
        },
      }),
      reducer: (state, { payload }) => {
        const { page } = payload;

        setState(state, {
          isFetchingSearchResults: true,
          searchResults: page > 1 ? state.searchResults : {},
          searchResultsOrder: page > 1 ? state.searchResultsOrder : [],
          searchPagination: page > 1 ? state.searchPagination : {},
          currentSearchParams: payload,
          errorFetchingSearchResults: null,
        });
      },
    },
    searchSuccess: (state, { payload }) => {
      const { results, pagination } = payload;
      const page = _get(pagination, 'page', 1);
      const pageSize = _get(pagination, 'pageSize', 20);
      const newResults = _keyBy(results, 'id');
      const newResultsKeys = results.map(r => r.id);

      // Put the results at the index corresponding to the page,
      // so first page results are at index 0, third page results are at index 60, etc.
      // Increase array size if necessary
      if (state.searchResultsOrder.length < page * pageSize) {
        state.searchResultsOrder.length = page * pageSize;
      }
      state.searchResultsOrder.splice(
        (page - 1) * pageSize,
        pageSize,
        ...newResultsKeys,
      );

      setState(state, {
        isFetchingSearchResults: false,
        hasFetchedSearchResults: true,
        searchPagination: pagination,
        searchResults: {
          ...state.searchResults,
          ...newResults,
        },
      });
    },
    searchError: (state, { payload: { errorFetchingSearchResults } }) => {
      setState(state, {
        isFetchingSearchResults: false,
        errorFetchingSearchResults,
      });
    },
    // Fetch a single item (provider or facility)
    fetchItemRequest(state) {
      setState(state, {
        isFetchingItem: true,
        errorFetchingItem: null,
        shouldDeleteItem: null,
      });
    },
    fetchItemSuccess(
      state,
      {
        payload: { item },
      },
    ) {
      // We can save provider's locations separately to show them on the details page
      const { type } = item;
      let newItems = {
        [item.id]: item,
      };

      if (type === SEARCH_TYPES.PROVIDER) {
        newItems = {
          ...newItems,
          ..._keyBy(
            item.locations.map(l => ({
              ...l,
              id: l.uuid,
              lat: l.latitude,
              lng: l.longitude,
            })),
            'uuid',
          ),
        };
      }

      state.isFetchingItem = false;
      state.searchResults = {
        ...state.searchResults,
        ...newItems,
      };
    },
    fetchItemError(
      state,
      {
        payload: { errorFetchingItem, shouldDeleteItem },
      },
    ) {
      setState(state, {
        shouldDeleteItem,
        errorFetchingItem,
        isFetchingItem: false,
      });
    },
    fetchSavedProvidersRequest(state) {
      setState(state, {
        errorFetchingSavedProviders: null,
        isFetchingSavedProviders: true,
      });
    },
    fetchSavedProvidersSuccess(
      state,
      {
        payload: { savedProviders },
      },
    ) {
      // Add isSaved field to each provider in searchResults
      state.savedProviders = _keyBy(savedProviders, 'id');
      state.searchResults = Object.keys(state.searchResults).reduce(
        (acc, id) => {
          acc[id] = state.searchResults[id];
          if (state.savedProviders[id]) {
            acc[id].isSaved = true;
          }

          return acc;
        },
        {},
      );
      state.hasFetchedSavedProviders = true;
      state.isFetchingSavedProviders = false;
    },
    fetchSavedProvidersError(
      state,
      {
        payload: { errorFetchingSavedProviders },
      },
    ) {
      setState(state, {
        errorFetchingSavedProviders,
        isFetchingSavedProviders: false,
      });
    },
    searchProvidersResetFields(state) {
      setState(state, initialState);
    },
    saveProviderRequest(state) {
      setState(state, {
        errorSavingProvider: null,
        isSettingSavedProvider: true,
      });
    },
    saveProviderSuccess(
      state,
      {
        payload: { provider },
      },
    ) {
      setState(state, {
        searchResults: {
          ...state.searchResults,
          [provider.id]: {
            ...state.searchResults[provider.id],
            isSaved: true,
          },
        },
        savedProviders: {
          ...state.savedProviders,
          [provider.id]: provider,
        },
        isSettingSavedProvider: false,
      });
    },
    saveProviderError(
      state,
      {
        payload: { errorSavingProvider },
      },
    ) {
      setState(state, {
        errorSavingProvider,
        isSettingSavedProvider: false,
      });
    },
    deleteFacilityRequest(state) {
      setState(state, {
        errorSavingFacility: null,
        isSettingSavedFacility: true,
      });
    },
    deleteFacilitySuccess(
      state,
      {
        payload: { id },
      },
    ) {
      setState(state, {
        searchResults: {
          ...state.searchResults,
          [id]: {
            ...state.searchResults[id],
            isSaved: false,
          },
        },
        savedFacilities: _omit(state.savedFacilities, id),
        isSettingSavedFacility: false,
      });
    },
    deleteFacilityError(
      state,
      {
        payload: { errorDeletingSavedFacility },
      },
    ) {
      setState(state, {
        errorDeletingSavedFacility,
        isSettingSavedProvider: false,
      });
    },
    deleteProviderRequest(state) {
      setState(state, {
        errorSavingProvider: null,
        isSettingSavedProvider: true,
      });
    },
    deleteProviderSuccess(
      state,
      {
        payload: { id },
      },
    ) {
      setState(state, {
        searchResults: {
          ...state.searchResults,
          [id]: {
            ...state.searchResults[id],
            isSaved: false,
          },
        },
        savedProviders: _omit(state.savedProviders, id),
        isSettingSavedProvider: false,
      });
    },
    deleteProviderError(
      state,
      {
        payload: { errorDeletingSavedProvider },
      },
    ) {
      setState(state, {
        errorDeletingSavedProvider,
        isSettingSavedProvider: false,
      });
    },
    fetchSavedFacilitiesRequest(state) {
      setState(state, {
        errorFetchingSavedFacilities: null,
        isFetchingSavedFacilities: true,
      });
    },
    fetchSavedFacilitiesSuccess(
      state,
      {
        payload: { savedFacilities },
      },
    ) {
      // Add isSaved field to each facility in searchResults
      state.searchResults = Object.keys(state.searchResults).reduce(
        (acc, id) => {
          acc[id] = state.searchResults[id];
          if (state.savedFacilities[id]) {
            acc[id].isSaved = true;
          }

          return acc;
        },
        {},
      );
      state.savedFacilities = _keyBy(savedFacilities, 'id');
      state.isFetchingSavedFacilities = false;
      state.hasFetchedSavedFacilities = true;
    },
    fetchSavedFacilitiesError(
      state,
      {
        payload: { errorFetchingSavedFacilities },
      },
    ) {
      setState(state, {
        errorFetchingSavedFacilities,
        isFetchingSavedFacilities: false,
      });
    },
    saveFacilityRequest(state) {
      setState(state, {
        errorSavingFacility: null,
        isSettingSavedFacility: true,
      });
    },
    saveFacilitySuccess(
      state,
      {
        payload: { facility },
      },
    ) {
      setState(state, {
        searchResults: {
          ...state.searchResults,
          [facility.id]: {
            ...state.searchResults[facility.id],
            isSaved: true,
          },
        },
        savedFacilities: {
          ...state.savedFacilities,
          [facility.id]: facility,
        },
        isSettingSavedFacility: false,
      });
    },
    saveFacilityError(
      state,
      {
        payload: { errorSavingFacility },
      },
    ) {
      setState(state, {
        errorSavingFacility,
        isSettingSavedFacility: false,
      });
    },
  },
});

const { actions, reducer } = findMedicalCareSlice;

export default reducer;
export const {
  searchRequest,
  searchSuccess,
  searchError,
  fetchItemRequest,
  fetchItemSuccess,
  fetchItemError,
  fetchSavedFacilitiesError,
  fetchSavedFacilitiesRequest,
  fetchSavedFacilitiesSuccess,
  fetchSavedProvidersError,
  fetchSavedProvidersRequest,
  fetchSavedProvidersSuccess,
  saveFacilityError,
  saveFacilityRequest,
  saveFacilitySuccess,
  saveProviderError,
  saveProviderRequest,
  saveProviderSuccess,
  deleteProviderRequest,
  deleteProviderSuccess,
  deleteProviderError,
  searchProvidersResetFields,
  deleteFacilityRequest,
  deleteFacilitySuccess,
  deleteFacilityError,
} = actions;
