import { put, takeLatest } from 'redux-saga/effects';

import * as adminSsoApi from '@app/api/admin/sso';
import {
  createSsoConfigError,
  createSsoConfigSuccess,
  fetchSsoConfigError,
  fetchSsoConfigSuccess,
  deleteSsoConfigError,
  deleteSsoConfigSuccess,
  enableSsoConfigSuccess,
  enableSsoConfigError,
  fetchSsoConfigRequest,
  createSsoConfigRequest,
  deleteSsoConfigRequest,
  enableSsoConfigRequest,
} from './index';

function* fetchSSOConfig() {
  try {
    const response = yield adminSsoApi.getSSOConfig();

    yield put(fetchSsoConfigSuccess({ response }));
  } catch (error) {
    // 404 is ok. It means there is no SSO config, that SSO is not enabled.
    if (error.status === 404) {
      yield put(fetchSsoConfigSuccess({ response: null }));
    } else {
      yield put(fetchSsoConfigError({ error }));
    }
  }
}

function* createSSOConfig({ payload }) {
  try {
    const response = yield adminSsoApi.createSSOConfig(payload);

    const { idp } = payload;

    yield put(
      createSsoConfigSuccess({
        response: { idp, enabled: false, ...response },
      }),
    );
  } catch (error) {
    yield put(createSsoConfigError({ error }));
  }
}

function* deleteSSOConfig() {
  try {
    yield adminSsoApi.deleteSSOConfig();

    yield put(deleteSsoConfigSuccess());
  } catch (error) {
    yield put(deleteSsoConfigError({ error }));
  }
}

function* enableSSOConfig() {
  try {
    const response = yield adminSsoApi.enableSSOConfig();

    yield put(enableSsoConfigSuccess({ response }));
  } catch (error) {
    yield put(enableSsoConfigError({ error }));
  }
}

export function* adminFetchSSOConfigWatcher() {
  yield takeLatest(fetchSsoConfigRequest, fetchSSOConfig);
}

export function* adminCreateSSOConfigWatcher() {
  yield takeLatest(createSsoConfigRequest, createSSOConfig);
}

export function* adminDeleteSSOConfigWatcher() {
  yield takeLatest(deleteSsoConfigRequest, deleteSSOConfig);
}

export function* adminEnableSSOConfigWatcher() {
  yield takeLatest(enableSsoConfigRequest, enableSSOConfig);
}
