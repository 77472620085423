/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const telehealthSlice = createSlice({
  name: 'telehealth',
  initialState: {
    telehealthConfiguration: [],
    error: '',
    isFetching: false,
    hasFetched: false,
  },
  reducers: {
    getTelehealthConfiguration: state => {
      state.isFetching = true;
    },
    getTelehealthConfigurationSuccess: (state, { payload }) => {
      state.isFetching = false;
      state.hasFetched = true;
      state.telehealthConfiguration = payload.telehealthConfiguration;
      state.isDefault = payload.isDefault;
    },
    getTelehealthConfigurationError: (state, { payload }) => {
      state.isFetching = false;
      state.hasFetched = true;
      state.error = payload;
    },
  },
});

const { actions, reducer } = telehealthSlice;

export const {
  getTelehealthConfiguration,
  getTelehealthConfigurationSuccess,
  getTelehealthConfigurationError,
} = actions;

export {
  selectTelehealthConfigurationIsDefault,
  selectTelehealthConfigurationIsFetching,
  selectTelehealthConfigurationHasFetched,
  selectTelehealthConfigurationError,
} from './selectors';

export default reducer;
