import { put, takeLatest } from 'redux-saga/effects';

import * as api from '@app/api/telehealthConfiguration';

import {
  getTelehealthConfiguration,
  getTelehealthConfigurationSuccess,
  getTelehealthConfigurationError,
} from './index';

function* fetchTelehealthConfiguration() {
  try {
    const {
      telehealthConfiguration,
      isDefault,
    } = yield api.fetchTelehealthConfiguration();
    yield put(
      getTelehealthConfigurationSuccess({ telehealthConfiguration, isDefault }),
    );
  } catch ({ message }) {
    yield put(
      getTelehealthConfigurationError(
        message || 'Failed to fetch telehealth configuration...',
      ),
    );
  }
}

export function* getTelehealthConfigurationWatcher() {
  yield takeLatest(getTelehealthConfiguration, fetchTelehealthConfiguration);
}
