import PropTypes from 'prop-types';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Root = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.customGrey.stone}`,
  padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
  color: theme.palette.customGrey.charcoal,
  '&:hover': {
    backgroundColor: theme.palette.customGrey.silver,
  },
  '& .item-content': {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    '& *': {
      marginRight: theme.spacing(0.5),
    },
    '& svg': {
      color: theme.palette.customGrey.ash,
      fontSize: '24px',
      marginRight: theme.spacing(1),
    },
  },
  '& .chevron-right-icon': {
    width: '24px',
    height: '24px',
    color: theme.palette.customGrey.charcoal,
  },
}));

const SavedItem = ({ children, onClick }) => (
  <Root onClick={onClick}>
    <Box className="item-content">{children}</Box>
    <Box display="flex" flexGrow={1} justifyContent="flex-end">
      <ChevronRightIcon className="chevron-right-icon" />
    </Box>
  </Root>
);

SavedItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

export default SavedItem;
