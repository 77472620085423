import { createSelector } from 'reselect';
import _isEqual from 'lodash/isEqual';

import { defaultCardList } from '@app/constants/telehealth';

const getTelehealthState = state => state.telehealth;

export const getTelehealthCards = createSelector(
  getTelehealthState,
  telehealth => telehealth.telehealthConfiguration,
);

export const getHasTelehealthProviders = createSelector(
  getTelehealthCards,
  cards => {
    const providers = cards.map(card => card.telehealthProvider);
    const defaultProviders = defaultCardList.map(
      defaultCard => defaultCard.telehealthProvider,
    );

    return !_isEqual(providers, defaultProviders);
  },
);

export const selectTelehealthConfiguration = createSelector(
  getTelehealthState,
  telehealth => telehealth.telehealthConfiguration,
);

export const selectTelehealthConfigurationIsDefault = createSelector(
  getTelehealthState,
  telehealth => telehealth.isDefault,
);

export const selectTelehealthConfigurationIsFetching = createSelector(
  getTelehealthState,
  telehealth => telehealth.isFetching,
);

export const selectTelehealthConfigurationError = createSelector(
  getTelehealthState,
  telehealth => telehealth.error,
);

export const selectTelehealthConfigurationHasFetched = createSelector(
  getTelehealthState,
  telehealth => telehealth.hasFetched,
);
